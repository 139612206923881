<template>
  <div class="content">
    <div class="columns">
      <div class="column">
        <h1 class="title is-1">{{ prep.term }}</h1>
      </div>
      <div class="column is-narrow">
        <b-button outlined type="is-primary" class="right" icon-left="check" @click="saveAndClose">Save & Close</b-button>
      </div>
      <div class="column is-narrow">
        <b-button type="is-primary" class="right" icon-left="content-save" @click="save">Save</b-button>
      </div>
    </div>
    <b-field horizontal label="Prep Term">
      <b-input
        v-model="prep.term"
        placeholder="e.g. chopped"
        @blur="save"/>
    </b-field>
  </div>
</template>

<script>
import db from "@/firebase/db"

export default {
  name: 'PrepTermView',
  props: [
    'id'
  ],
  data() {
    return {
      prep: {},
    };
  },
  created: function () {
    // fetch our ingredient
    db.collection('prep_terms')
      .doc(this.id)
      .get()
      .then(snapshot => {
        this.prep = snapshot.data()
      })
  },
  methods: {
    async saveAndClose(){
      this.save()
        .then(() => {
          this.$router.go(-1)
        })
    },
    async save(){
      db.collection('prep_terms')
        .doc(this.id)
        .set(this.prep)
        .then(() => {
          this.$buefy.toast.open({type: 'is-success', message: `Prep term successfully updated!`})
        })
        .catch((error) => {
          this.$buefy.toast.open({type: 'is-danger', message: `Error saving prep term: ${error}`})
        });
    },
  }
}
</script>
